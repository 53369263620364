import { Typography } from '@mui/material'
import React from 'react'

const DashboardPage = () => {
  return (
    <div>
      <Typography variant="h3" textAlign="center" sx={{mt:10,fontWeight:600}}>Welcome Maroo Steel</Typography>
    </div>
  )
}

export default DashboardPage
