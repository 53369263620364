import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
} from "@mui/material";
import ImageUpload from "../components/common-form/image-common";
import axiosInstance from "../hook/axiosInstance";
import { DataGrid } from "@mui/x-data-grid";
import VideosUpload from "../components/common-form/video-common";
import * as Yup from "yup";
import { toast } from "react-toastify";

const notify = (msg) => {
  toast.success(msg);
};

const VideosPage = () => {
  const [initialData, setInitialData] = useState([]);
  const [materialName, setMaterialName] = useState("");
  const [imageFolderPath, setImageFolderPath] = useState("");
  const [editItemId, setEditItemId] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const initialValues = {
    created_by: "",
    status: "Yes",
    image: "",
  };

  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
    image: Yup.string().required("Image is required"),
  });

  const editInitialValues = {
    created_by: "",
    status: "Yes",
    image: "",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/video-get");
      const profileData = response.data;
      setInitialData(profileData?.data);
      setImageFolderPath(profileData?.image_folder);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("status", values.status); // Use values.material_name
      formData.append("image", values.image);
      formData.append("created_by", 1);

      const response = await axiosInstance.post("/video-save", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        notify("Video data saved successfully!")
        fetchData();
        setAddModal(false);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setAddModal(false);
    }
  };

  const handleEditSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      const test = { created_by: 1 };

      const data = { ...values, ...test };
      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axiosInstance.post(`/video-save`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure correct content type
        },
      });
      if (response) {
        notify("Video data edited successfully!")
        fetchData();
      }
    } catch (error) {
    } finally {
      setEditModal(false);
    }
  };
  const handleEdit = (id) => {
    // Open modal or navigate to edit page with the selected item's ID
    setEditItemId(id);
    setEditModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/gallery/delete/${id}`);
      fetchData();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleEditModalClose = () => {
    setEditModal(false);
  };

  const handleAddModalClose = () => {
    setAddModal(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "status", headerName: "Status", width: 200 },
    // { field: "material_type", headerName: "Material Type", width: 150 },
    // { field: "created_by", headerName: "Created By", width: 150 },
    {
      field: "image",
      headerName: "Image",
      width: 200,
      renderCell: (params) => {
        return (
          // <img src={params.value} alt="Item" style={{ maxWidth: "100%" }} />
          <video controls style={{ height: "50px", width: "100px" }}>
            <source src={`${apiurl}/video/${params.value}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];
  const apiurl = process.env.REACT_APP_IMAGE_KEY;
  return (
    <>
      <div>
        <Stack sx={{ mt: 10 }} direction="row" width="100%">
          <Button
            sx={{ textAlign: "right" }}
            variant="contained"
            onClick={() => setAddModal(true)}
          >
            Add Videos
          </Button>
        </Stack>
        <Dialog
          open={addModal}
          maxWidth="md"
          fullWidth
          onClose={handleAddModalClose}
        >
          <DialogTitle>Add Item</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  {/* Material Type Select */}
                  <Box>
                    <Typography
                      fontStyle="bold"
                      fontWeight={600}
                      fontSize={16}
                      mb={1}
                    >
                      Status
                    </Typography>
                    {/* <Field name="material_type">
                      {({ field }) => (
                        <Select
                          fullWidth
                          {...field}
                          value={materialName}
                          onChange={(e) => {
                            setMaterialName(e.target.value);
                            setFieldValue("material_type", e.target.value);
                          }}
                        >
                          <MenuItem value="">Status</MenuItem>
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      )}
                    </Field> */}
                    <Field name="material_type">
                      {({ field }) => (
                        <Select
                          fullWidth
                          {...field}
                          value={materialName}
                          onChange={(e) => {
                            setMaterialName(e.target.value);
                            setFieldValue("material_type", e.target.value);
                          }}
                        >
                          <MenuItem value="">Status</MenuItem>
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      )}
                    </Field>

                    <Box mt={2} color="red">
                      <ErrorMessage name="material_type" />
                    </Box>
                  </Box>

                  {/* <Box mt={5}>
                    <Typography fontStyle="bold" fontWeight={600} fontSize={16}>
                      Material Name
                    </Typography>
                    <Box mt={2}>
                      <Field
                        name="matrial_name"
                        render={({ field }) => (
                          <TextEditor
                            name={field.name}
                            value={field.value}
                            onChange={setFieldValue}
                          />
                        )}
                      />
                    </Box>
                  </Box> */}

                  {/* Image upload */}
                  <Box mt={10}>
                    <Typography
                      fontStyle="bold"
                      fontWeight={600}
                      fontSize={16}
                      mb={1}
                    >
                      Upload Video
                    </Typography>

                    <Field
                      name="image"
                      label="Upload Image"
                      render={({ field, form }) => {
                        return (
                          <VideosUpload
                            name={"image"}
                            value={values?.image}
                            onChange={setFieldValue}
                            id={values && values.id}
                            imageFolderPath={"video"}
                          />
                        );
                      }}
                    />
                    <Box mt={2} color="red">
                      <ErrorMessage name="image" />
                    </Box>
                  </Box>

                  <div className="col-md-12 mt-15">
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn btn-primary me-3"
                    >
                      <span className="indicator-label">Save</span>
                    </Button>

                    <Button
                      type="reset"
                      variant="contained"
                      color="error"
                      onClick={() => setAddModal(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        <Box mt={3} mb={3} style={{ height: 400, width: "100%" }}>
          <Typography fontWeight={600} variant="h5">
            All Videos
          </Typography>
          <DataGrid
            rows={initialData && initialData.length > 0 ? initialData : []}
            columns={columns}
            pageSize={10}
          />
        </Box>
      </div>
      <Dialog
        open={editModal}
        maxWidth="md"
        fullWidth
        onClose={handleEditModalClose}
      >
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItemId?.id ? editItemId : editInitialValues}
            enableReinitialize={true}
            onSubmit={handleEditSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                {/* Material Type Select */}
                <Box>
                  <Typography
                    fontStyle="bold"
                    fontWeight={600}
                    fontSize={16}
                    mb={1}
                  >
                    Status
                  </Typography>
                  <Field name="material_type">
                    {({ field }) => (
                      <Select
                        fullWidth
                        {...field}
                        value={materialName}
                        onChange={(e) => {
                          setMaterialName(e.target.value);
                          setFieldValue("material_type", e.target.value);
                        }}
                      >
                        <MenuItem value="">Status</MenuItem>
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    )}
                  </Field>
                </Box>
                <Box mt={10}>
                  <Typography
                    fontStyle="bold"
                    fontWeight={600}
                    fontSize={16}
                    mb={1}
                  >
                    Upload Video
                  </Typography>

                  <Field
                      name="image"
                      label="Upload Image"
                      render={({ field, form }) => {
                        return (
                          <VideosUpload
                            name={"image"}
                            value={values?.image}
                            onChange={setFieldValue}
                            id={values && values.id}
                            imageFolderPath={"video"}
                          />
                        );
                      }}
                    />
                  <Box mt={2} color="red">
                    <ErrorMessage name="image" />
                  </Box>
                </Box>

                <div className="col-md-12 mt-15">
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn btn-primary me-3"
                  >
                    <span className="indicator-label">Save</span>
                  </Button>

                  <Button
                    type="reset"
                    variant="contained"
                    color="error"
                    onClick={() => setEditModal(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideosPage;
