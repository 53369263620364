import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
} from "@mui/material";
import ImageUpload from "../components/common-form/image-common";
import { TextEditor } from "../components/text-editor";
import axiosInstance from "../hook/axiosInstance";
import { DataGrid } from "@mui/x-data-grid";
import * as Yup from "yup";
import { toast } from "react-toastify";

const notify = (msg) => {
  toast.success(msg);
};

const PhotosPage = () => {
  const apiurl = process.env.REACT_APP_IMAGE_KEY;
  const [imageFolderPath, setImageFolderPath] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [materialName, setMaterialName] = useState("");
  const [editItemId, setEditItemId] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const initialValues = {
    // content: "",
    created_by: "",
    material_type: "",
    image: "",
    matrial_name: "",
  };

  const validationSchema = Yup.object().shape({
    // content: Yup.string().required("Content is required"),
    material_type: Yup.string().required("Material Type is required"),
    image: Yup.string().required("Image is required"),
    matrial_name: Yup.string().required("Material Name is required"),
  });

  const editInitialValues = {
    content: "",
    created_by: "",
    material_type: "",
    image: "",
    matrial_name: "",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/gallery-get");
      const profileData = response.data;
      console.log("profileData:", profileData);
      setInitialData(profileData.data);
      setImageFolderPath(profileData?.image_folder);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log("values:", values);
    try {
      const formData = new FormData();
      formData.append("matrial_name", values.matrial_name);
      formData.append("material_type", values.material_type); // Use values.material_name
      formData.append("image", values.image);
      formData.append("created_by", 1);

      const response = await axiosInstance.post("/gallery-save", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure correct content type
        },
      });

      if (response) {
        notify("Gallary data saved successfully! ")
        setMaterialName("");
        resetForm();
        fetchData();
        setAddModal(false);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setAddModal(false);
    }
  };

  const handleEditSubmit = async (values, { resetForm }) => {
    console.log("values:", values);
    try {
      let formData = new FormData();
      const test = { created_by: 1, image: values.image };

      const data = { ...values, ...test };
      console.log("Data", formData);

      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axiosInstance.post(`/gallery-save`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure correct content type
        },
      });
      if (response) {
        notify("Gallary data edited successfully! ")
        resetForm();
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setEditModal(false);
    }
  };
  const handleEdit = (id) => {
    // Open modal or navigate to edit page with the selected item's ID
    setEditItemId(id);
    setEditModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/gallery/delete/${id}`);
      fetchData();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleEditModalClose = () => {
    setEditModal(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "matrial_name", headerName: "Material Name", width: 200 },
    { field: "material_type", headerName: "Material Type", width: 150 },
    // { field: "created_by", headerName: "Created By", width: 150 },
    {
      field: "image",
      headerName: "Image",
      width: 200,
      renderCell: (params) => {
        const imagePath = `${apiurl}/gallery/${params?.value}`;
        return (
          <img src={imagePath} alt="Item" height={"50px"} width={"50px"} />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleAddModalClose = () => {
    setAddModal(false);
  };

  return (
    <>
      <div>
        <Stack sx={{ mt: 10 }} direction="row" width="100%">
          <Button
            sx={{ textAlign: "right" }}
            variant="contained"
            onClick={() => setAddModal(true)}
          >
            Add Gallery Photos
          </Button>
        </Stack>
        <Dialog
          open={addModal}
          maxWidth="md"
          fullWidth
          onClose={handleAddModalClose}
        >
          <DialogTitle>Add Item</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ setFieldValue, values, errors, handleChange }) => (
                <Form>
                  {console.log({ errors })}
                  {/* Material Type Select */}
                  <Box>
                    <Typography
                      fontStyle="bold"
                      fontWeight={600}
                      fontSize={16}
                      mb={1}
                    >
                      Material Type
                    </Typography>
                    <Field name="material_type">
                      {({ field }) => (
                        <Select
                          fullWidth
                          {...field}
                          value={materialName}
                          onChange={(e) => {
                            setMaterialName(e.target.value);
                            setFieldValue("material_type", e.target.value);
                          }}
                        >
                          <MenuItem value="">Select Material Name</MenuItem>
                          <MenuItem value="all">All</MenuItem>
                          <MenuItem value="flat">Flat</MenuItem>
                          <MenuItem value="pipesandtubes">
                            Pipes & Tubes
                          </MenuItem>
                          <MenuItem value="semi-finished">
                            Semi Finished
                          </MenuItem>
                          <MenuItem value="aluminium">Aluminium</MenuItem>
                          <MenuItem value="ferro-alloy">Ferro Alloy</MenuItem>
                          <MenuItem value="stainless-steel">
                            stainless Steel
                          </MenuItem>
                          <MenuItem value="long">Long</MenuItem>
                        </Select>
                      )}
                    </Field>
                    <Box mt={7} color="red">
                      <ErrorMessage name="material_type" />
                    </Box>
                  </Box>

                  <Box mt={5}>
                    <Typography fontStyle="bold" fontWeight={600} fontSize={16}>
                      Material Name
                    </Typography>
                    {/* <Box mt={2}>
                      <Field
                        name="matrial_name"
                        render={({ field }) => (
                          <TextEditor
                            name={field.name}
                            value={field.value}
                            onChange={setFieldValue}
                          />
                        )}
                      />
                      <Box mt={7} color="red">
                        <ErrorMessage name="matrial_name" />
                      </Box>
                    </Box> */}
                    <Field
                      name="matrial_name"
                      as={TextField}
                      label="Material Name"
                      fullWidth
                      value={values.matrial_name}
                      onChange={handleChange}
                      error={Boolean(errors.matrial_name)}
                      helperText={errors.matrial_name}
                    />
                  </Box>

                  {/* Image upload */}
                  <Box mt={10}>
                    <Typography
                      fontStyle="bold"
                      fontWeight={600}
                      fontSize={16}
                      mb={1}
                    >
                      Upload Image
                    </Typography>

                    <Field
                      name="image"
                      label="Upload Image"
                      render={({ field, form }) => (
                        <ImageUpload
                          id={values && values.id}
                          name={"image"}
                          value={field.value}
                          onChange={setFieldValue}
                          imageFolderPath={"gallery"}
                        />
                      )}
                    />
                    <Box mt={2} color="red">
                      <ErrorMessage name="image" />
                    </Box>
                  </Box>

                  <div className="col-md-12 mt-15">
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn btn-primary me-3"
                    >
                      <span className="indicator-label">Save</span>
                    </Button>

                    <Button
                      type="reset"
                      variant="contained"
                      color="error"
                      onClick={() => setAddModal(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        <Box mt={3} mb={3} style={{ height: 400, width: "100%" }}>
          <Typography fontWeight={600} variant="h5">
            All Gallery
          </Typography>
          <DataGrid
            rows={initialData && initialData?.length > 0 ? initialData : []}
            columns={columns}
            pageSize={10}
          />
        </Box>
      </div>
      <Dialog
        open={editModal}
        maxWidth="md"
        fullWidth
        onClose={handleEditModalClose}
      >
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItemId?.id ? editItemId : editInitialValues}
            enableReinitialize={true}
            onSubmit={handleEditSubmit}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, values, errors, handleChange }) => (
              <Form>
                <Box mt={2}>
                  <Typography fontWeight={600} fontSize={16} mb={1}>
                    Material Type
                  </Typography>
                  <Field name="material_type">
                    {({ field }) => (
                      <Select
                        fullWidth
                        {...field}
                        value={values.material_type}
                        onChange={(e) => {
                          setMaterialName(e.target.value);
                          setFieldValue("material_type", e.target.value);
                        }}
                      >
                        <MenuItem value="">Select Material Name</MenuItem>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="flat">Flat</MenuItem>
                        <MenuItem value="pipesandtubes">Pipes & Tubes</MenuItem>
                        <MenuItem value="semi-finished">Semi Finished</MenuItem>
                        <MenuItem value="aluminium">Aluminium</MenuItem>
                        <MenuItem value="ferro-alloy">Ferro Alloy</MenuItem>
                        <MenuItem value="stainless-steel">
                          stainless Steel
                        </MenuItem>
                        <MenuItem value="long">Long</MenuItem>
                      </Select>
                    )}
                  </Field>
                  <Box mt={2} color="red">
                    <ErrorMessage name="material_type" />
                  </Box>
                </Box>

                <Box mt={2}>
                  <Typography fontWeight={600} fontSize={16} mb={1}>
                    Material Name
                  </Typography>
                  <Field
                    name="matrial_name"
                    as={TextField}
                    label="Material Name"
                    fullWidth
                    value={values.matrial_name}
                    onChange={handleChange}
                    error={Boolean(errors.matrial_name)}
                    helperText={errors.matrial_name}
                  />
                  {/* <Box mt={7} color="red">
                    <ErrorMessage name="matrial_name" />
                  </Box> */}
                </Box>

                <Box mt={2}>
                  <Typography fontWeight={600} fontSize={16} mb={1}>
                    Upload Image
                  </Typography>

                  <Field
                    name="image"
                    label="Upload Image"
                    render={({ field, form }) => (
                      <ImageUpload
                        id={values && values.id}
                        name={"image"}
                        value={field.value}
                        imageFolderPath={"gallery"}
                        onChange={setFieldValue}
                      />
                    )}
                  />

                  <Box mt={7} color="red">
                    <ErrorMessage name="image" />
                  </Box>
                </Box>

                <DialogActions>
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn btn-primary me-3"
                  >
                    <span className="indicator-label">Update</span>
                  </Button>

                  <Button
                    type="reset"
                    onClick={handleEditModalClose}
                    variant="contained"
                    color="error"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PhotosPage;
