// App.js
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import AboutPage from "./pages/about";
import CareerPage from "./pages/careers";
import CompanyProfilePAge from "./pages/company-profile";
import ContactPage from "./pages/contact";
import CurrentBusiness from "./pages/current-business";
import EnquiryPage from "./pages/enquiry";
import ExportPage from "./pages/export";
import HistoryPage from "./pages/history";
import ImportPage from "./pages/import";
import PhotosPage from "./pages/photos";
import ServicePage from "./pages/service";
import SupportPage from "./pages/support";
import TeamPage from "./pages/team";
import VideosPage from "./pages/videos";
import VisionMissionPage from "./pages/vision-mision";
import ChairmanNotesPage from "./pages/chairman's-note";
import Sidebar from "./components/sidebar";
import SignIn from "./components/login-form";
import DashboardPage from "./pages/dashboard";
import { useEffect, useState } from "react";



function App() {
  // const isAuthenticated = false; // Replace with your authentication logic
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const pathName = localStorage.getItem("route");
    const redirectPerformed = localStorage.getItem("redirectPerformed");
  
    if (window && pathName && !redirectPerformed) {
      localStorage.setItem("redirectPerformed", "true");
      window.location.href = pathName;
    }
  }, []);
  
  useEffect(() => {
    // Check if token exists in sessionStorage
    const token = sessionStorage.getItem("token");
    if (token) {
      // window.location.href="/"
    }
    setIsAuthenticated(!!token); // Set isAuthenticated to true if token exists, false otherwise
  }, []);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          isAuthenticated ? (
            <Sidebar>
              <Routes>
                <Route path="/" element={<DashboardPage />} />
                <Route path="home" element={<HomePage />} />
                <Route path="/enquiry" element={<AboutPage />} />
                <Route path="/careers" element={<CareerPage />} />
                <Route
                  path="/chairman's-note"
                  element={<ChairmanNotesPage />}
                />
                <Route
                  path="/company-profile"
                  element={<CompanyProfilePAge />}
                />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/current-business" element={<CurrentBusiness />} />
                <Route path="/enquiry" element={<EnquiryPage />} />
                <Route path="/export" element={<ExportPage />} />
                <Route path="/history" element={<HistoryPage />} />
                <Route path="/import" element={<ImportPage />} />
                <Route path="/photos" element={<PhotosPage />} />
                <Route path="/service" element={<ServicePage />} />
                <Route path="/support" element={<SupportPage />} />
                <Route path="/team" element={<TeamPage />} />
                <Route path="/videos" element={<VideosPage />} />
                <Route path="/vision-mission" element={<VisionMissionPage />} />
                <Route path="/login" element={<SignIn />} />
              </Routes>
            </Sidebar>
          ) : (
            <SignIn />
          )
        }
      />
    </Routes>
  );
}

export default App;
