import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

const ImageUpload = ({
  name,
  value,
  onChange,
  imageFolderPath,
  id,
  clearImage,
}) => {
  const [image, setImage] = useState(null);
  const apiurl = process.env.REACT_APP_IMAGE_KEY;
  useEffect(() => {
    if (clearImage) {
      setImage(null)
    }
  }, [clearImage]);

  useEffect(() => {
    if (value && id && imageFolderPath) {
      const imagePath = `${apiurl}/${imageFolderPath}/${value}`;
      setImage(imagePath);
    }
  }, [value]);

  const handleChange = (event) => {
    const file = event.currentTarget.files[0];
    onChange(name, file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Stack direction={"row"} gap={10}>
      <Box>
        <input type="file" onChange={(event) => handleChange(event)} />
      </Box>
      <Box alignItems="center">
        {image && (
          <div>
            <img src={image} alt="Preview" style={{ maxWidth: "200px" }} />
          </div>
        )}
      </Box>
    </Stack>
  );
};

export default ImageUpload;
