import React, { useEffect, useState } from "react";
import axiosInstance from "../hook/axiosInstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Box, Typography } from "@mui/material";
import { TextEditor } from "../components/text-editor";
import * as Yup from "yup";
import { toast } from "react-toastify";

const notify = (msg) => {
  toast.success(msg);
};

const SupportPage = () => {
  const [initialData, setInitialData] = useState();
  const initialValues = {
    content: "",
    created_by: 1,
  };

  const validationSchema = Yup.object().shape({
    content: Yup.string().required("Content is required"),
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("support-get");
      const profileData = response.data; // Assuming the response contains the profile data
      setInitialData(profileData.data[0]);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      const test = { created_by: 1 };

      const data = { ...values, ...test };
      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      let response;
      // if (initialData && initialData?.id) {
      //   response = await axiosInstance.put(
      //     `support-save`,
      //     data
      //   );
      // } else {
      response = await axiosInstance.post(`/support-save`, data);
      // }
      if (response) {
        notify("Support data saved successfully!");
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      id="kt_app_content"
      className="app-content flex-column-fluid"
      style={{ overflowY: "scroll" }}
    >
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ overflowY: "scroll" }}
      >
        <div className="row gx-5 gx-xl-12 pt-10">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body py-4">
                <Formik
                  initialValues={
                    initialData && initialData?.id ? initialData : initialValues
                  }
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, values }) => {
                    return (
                      <Form
                        id="kt_ecommerce_settings_general_form"
                        className="form"
                      >
                        <Box>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Support
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red">
                              <ErrorMessage name="content" />
                            </Box>
                          </Box>
                        </Box>

                        <Box mt={10}>
                          {" "}
                          <div className="col-md-12 mt-15">
                            <button
                              type="submit"
                              className="btn btn-primary me-3"
                            >
                              <span className="indicator-label">Save</span>
                            </button>

                            <button type="reset" className="btn btn-light me-3">
                              Cancel
                            </button>
                          </div>
                        </Box>

                        {/* </div> */}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default SupportPage;
