import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextEditor } from "../text-editor";
import { Box, Typography } from "@mui/material";
import ImageUpload from "../common-form/image-common";
import VideosUpload from "../common-form/video-common";

const HomePageDetails = ({
  handleSubmit,
  initialValues,
  initialData,
  validationSchema,
  imageFolderPath
}) => {

  return (
    <div>
     
      <div
        id="kt_app_content"
        className="app-content flex-column-fluid"
        style={{ overflowY: "scroll" }}
      >
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
          style={{ overflowY: "scroll" }}
        >
          <div className="row gx-5 gx-xl-12 pt-10">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body py-4">
                  <Formik
                    initialValues={
                      initialData && initialData?.id
                        ? initialData
                        : initialValues
                    }
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ setFieldValue, values }) => {
                      return (
                        <Form
                          id="kt_ecommerce_settings_general_form"
                          className="form"
                        >
                          <Box mt={2}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={600}
                              fontSize={16}
                            >
                       
                              About Us
                            </Typography>
                            <Box mt={2}>
                              <Field
                                name="about_us"
                                render={({ field, form }) => (
                                  <>
                                    <TextEditor
                                      name={field.name}
                                      value={field.value}
                                      onChange={setFieldValue}
                                    />
                                    <Box mt={7} color="red">
                                      <ErrorMessage name="about_us" />
                                    </Box>
                                  </>
                                )}
                              />
                            </Box>
                            <div
                              className="col-md-6"
                              style={{ paddingTop: 20 }}
                            >
                              <Typography
                                fontStyle="bold"
                                fontWeight={300}
                                fontSize={16}
                              >
                                About US Video
                              </Typography>
                              <Field
                                name="about_us_video"
                                label="Upload Image"
                                render={({ field, form }) => {
                                  return (
                                    <VideosUpload
                                      name={"about_us_video"}
                                      value={values?.about_us_video}
                                      onChange={setFieldValue}
                                      id={values && values.id}
                                      imageFolderPath={"home"}
                                    />
                                  );
                                }}
                              />
                              <Box mt={2} color="red">
                                <ErrorMessage name="about_us_video" />
                              </Box>
                            </div>
                          </Box>

                          <Box mt={10}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={600}
                              fontSize={16}
                            >
                              Service
                            </Typography>
                            <Box mt={2}>
                              <Field
                                name="service"
                                render={({ field, form }) => (
                                  <TextEditor
                                    name={field.name}
                                    value={field.value}
                                    onChange={setFieldValue}
                                  />
                                )}
                              />
                               <Box mt={7} color="red">
                                <ErrorMessage name="service" />
                              </Box>
                            </Box>
                            <div
                              className="col-md-6"
                              style={{ paddingTop: 100 }}
                            >
                              <Typography
                                fontStyle="bold"
                                fontWeight={300}
                                fontSize={16}
                              >
                                Service Image
                              </Typography>

                              <Field
                                name="service_image"
                                label="Upload Image"
                                render={({ field, form }) => (
                                  <ImageUpload
                                    id={values && values.id}
                                    name={"service_image"}
                                    value={field.value}
                                    onChange={setFieldValue}
                                    imageFolderPath={"home"}
                                  />
                                )}
                              />
                               <Box mt={2} color="red">
                                <ErrorMessage name="service_image" />
                              </Box>
                            </div>
                          </Box>

                          <Box mt={10}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={600}
                              fontSize={16}
                            >
                              {" "}
                              Import
                            </Typography>
                            <Box mt={2}>
                              <Field
                                name="import_home"
                                render={({ field, form }) => (
                                  <TextEditor
                                    name={field.name}
                                    value={field.value}
                                    onChange={setFieldValue}
                                  />
                                )}
                              />
                               <Box mt={7} color="red">
                                <ErrorMessage name="import_home" />
                              </Box>
                            </Box>
                            <div
                              className="col-md-6"
                              style={{ paddingTop: 100 }}
                            >
                              <Typography
                                fontStyle="bold"
                                fontWeight={300}
                                fontSize={16}
                              >
                                Import Home Image
                              </Typography>
                              <Field
                                name="import_home_img"
                                label="Upload Image"
                                render={({ field, form }) => (
                                  <ImageUpload
                                    id={values && values.id}
                                    name={"import_home_img"}
                                    value={field.value}
                                    onChange={setFieldValue}
                                    imageFolderPath={"home"}
                                  />
                                )}
                              />
                              <Box mt={2} color="red">
                                <ErrorMessage name="import_home_img" />
                              </Box>
                            </div>
                          </Box>

                          <Box mt={10}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={600}
                              fontSize={16}
                            >
                              {" "}
                              Export Home
                            </Typography>
                            <Box mt={2}>
                              <Field
                                name="export_home"
                                render={({ field, form }) => (
                                  <TextEditor
                                    name={field.name}
                                    value={field.value}
                                    onChange={setFieldValue}
                                  />
                                )}
                              />
                               <Box mt={7} color="red">
                                <ErrorMessage name="export_home" />
                              </Box>
                            </Box>
                            <div
                              className="col-md-6"
                              style={{ paddingTop: 100 }}
                            >
                              <Typography
                                fontStyle="bold"
                                fontWeight={300}
                                fontSize={16}
                              >
                                Export Home Image
                              </Typography>

                              <Field
                                name="export_home_img"
                                label="Upload Image"
                                render={({ field, form }) => (
                                  <ImageUpload
                                    id={values && values.id}
                                    name={"export_home_img"}
                                    value={field.value}
                                    onChange={setFieldValue}
                                    imageFolderPath={"home"}
                                  />
                                )}
                              />
                            </div>
                            <Box mt={2} color="red">
                                <ErrorMessage name="export_home_img" />
                              </Box>
                          </Box>

                          <div className="col-md-12 mt-3">
                            <button
                              type="submit"
                              className="btn btn-primary me-3"
                            >
                              <span className="indicator-label">Save</span>
                            </button>

                            <button type="reset" className="btn btn-light me-3">
                              Cancel
                            </button>
                          </div>
                          {/* </div> */}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      );
    </div>
  );
};

export default HomePageDetails;
