// import axios from 'axios';

// const token = sessionStorage.getItem('token');
// console.log('tokendsdsds:', token);

// // Check if token is available
// if (!token) {
//   // Handle the case where token is not available
//   console.error('Token not found in sessionStorage');
// }

// // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTcxMTU5NjE0MSwiZXhwIjoxNzExNjAzMzQxfQ.5cxgFtOxoY5LsvMoodumXvGtBaF-z1ERnzvXxRv8ESE'; // Replace with your actual bearer token
 
// const apiKey ="http://localhost:3000/api";
// console.log('apiKey:', apiKey);

// const axiosInstance = axios.create({
//   baseURL: apiKey,
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${token}` // Set the bearer token in the headers
//   }
// });

// export default axiosInstance;


import axios from 'axios';

const token = sessionStorage.getItem('token');

// Check if token is available
if (!token) {
  // Handle the case where token is not available
  console.error('Token not found in sessionStorage');
}


const apiKey = process.env.REACT_APP_API_KEY;
console.log('apiKey:dsdsdsd', apiKey);

const axiosInstance = axios.create({
  baseURL: apiKey,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Check if the error response contains "Invalid token" message
  if (error.response && error.response.data && error.response.data.message === "Invalid token") {
    // Navigate to the home page
    sessionStorage.removeItem("token")
    window.location.href = '/login'; // Replace '/' with the actual URL of your home page
  }
  return Promise.reject(error);
});

export default axiosInstance;
