// HomePage.js
import React, { useEffect, useState } from "react";
import { CommonForm } from "../components/common-form";
import TeamPageDetails from "../components/team-page-details";
import axiosInstance from "../hook/axiosInstance";
import * as Yup from "yup";
import { toast } from "react-toastify";

const notify = (msg) => {
  toast.success(msg);
};

const TeamPage = () => {
  const [imageFolderPath, setImageFolderPath] = useState("");
  const [initialData, setInitialData] = useState();

  const initialValues = {
    content: "",
    image_1: "",
    image_2: "",
    image_3: "",
    image_4: "",
    image_4_name: "",
    image_4_desgination: "",
    // image_4_content: "",
    image_5: "",
    image_5_name: "",
    image_5_desgination: "",
    // image_5_content: "",
    image_6: "",
    image_6_name: "",
    image_6_desgination: "",
    // image_6_content: "",
    created_by: 1,
  };

  const validationSchema = Yup.object().shape({
    content: Yup.string().required("Content is required"),
    image_1: Yup.string().required("Image 1 is required"),
    image_2: Yup.string().required("Image 2 is required"),
    image_3: Yup.string().required("Image 3 is required"),
    image_4: Yup.string().required("Image 4 is required"),
    image_4_name: Yup.string().required("Image 4 name is required"),
    image_4_desgination: Yup.string().required(
      "Image 4 designation is required"
    ),
    // image_4_content: Yup.string().required("Image 4 content is required"),
    image_5: Yup.string().required("Image 5 is required"),
    image_5_name: Yup.string().required("Image 5 name is required"),
    image_5_desgination: Yup.string().required(
      "Image 5 designation is required"
    ),
    // image_5_content: Yup.string().required("Image 5 content is required"),
    image_6: Yup.string().required("Image 6 is required"),
    image_6_name: Yup.string().required("Image 6 name is required"),
    image_6_desgination: Yup.string().required(
      "Image 6 designation is required"
    ),
    // image_6_content: Yup.string().required("Image 6 content is required"),
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("team-get");
      const profileData = response.data; // Assuming the response contains the profile data
      setInitialData(profileData.data[0]);
      setImageFolderPath(profileData?.image_folder);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };
  useEffect(() => {

    fetchData();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      const test = { created_by:1};

      const data = { ...values, ...test };
      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // if (initialData && initialData?.id) {
      //   const response = await axiosInstance.put(
      //     `/team/update/${initialData?.id}`,
      //     data,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //     }
      //   );
      // } else {
        const response = await axiosInstance.post(`/team-save`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        
      // }
      if(response){
        notify("Team data saved successfully")
        fetchData()
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <TeamPageDetails
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      initialData={initialData}
      validationSchema={validationSchema}
      imageFolderPath={imageFolderPath}
    />
  );
};

export default TeamPage;
