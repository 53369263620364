import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextEditor } from "../text-editor";
import { Box, Typography } from "@mui/material";
import ImageUpload from "../common-form/image-common";

const CompanyProfileDetails = ({
  handleSubmit,
  initialValues,
  initialData,
  validationSchema,
  imageFolderPath,
}) => {
  return (
    <div
      id="kt_app_content"
      className="app-content flex-column-fluid"
      style={{ overflowY: "scroll" }}
    >
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ overflowY: "scroll" }}
      >
        <div className="row gx-5 gx-xl-12 pt-10">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body py-4">
                <Formik
                  initialValues={
                    initialData && initialData?.id ? initialData : initialValues
                  }
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({ setFieldValue, values }) => {
                    return (
                      <Form
                        id="kt_ecommerce_settings_general_form"
                        className="form"
                      >
                        <Box>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Company Profile Main Heading
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="main_header"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={values.main_header}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="main_header" />
                            </Box>
                          </Box>
                        </Box>

                        {/* Main Heading  */}
                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Company Profile Main Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="main_content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="main_content" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Main Image
                            </Typography>
                            <Field
                              name="main_content_image"
                              label="Upload Image"
                              render={({ field, form }) => {
                                return (
                                  <ImageUpload
                                    name={"main_content_image"}
                                    value={values?.main_content_image}
                                    onChange={setFieldValue}
                                    id={values && values.id}
                                    imageFolderPath={"company_profile"}
                                  />
                                );
                              }}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="main_content_image" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            Company Profile Export Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="export_content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="export_content" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Export Image
                            </Typography>

                            <Field
                              name="export_image"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"export_image"}
                                  value={field.value}
                                  imageFolderPath={"company_profile"}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="export_image" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Company Profile Import Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="import_content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="import_content" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Import Image
                            </Typography>
                            <Field
                              name="import_image"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"import_image"}
                                  value={field.value}
                                  imageFolderPath={"company_profile"}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="import_image" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Company Profile Vision Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="vision_content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="vision_content" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Vision Image
                            </Typography>

                            <Field
                              name="vision_image"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"vision_image"}
                                  value={field.value}
                                  imageFolderPath={"company_profile"}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="vision_image" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Company Profile Mission Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="mission_content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="mission_content" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Mission Image
                            </Typography>
                            {/* <Field
                            type="file"
                            name="mission_image"
                            className="form-control"
                            onChange={(event) => {
                              setFieldValue(
                                "mission_image",
                                event.currentTarget.files[0] || ""
                              );
                            }}
                          /> */}
                            <Field
                              name="mission_image"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  imageFolderPath={"company_profile"}
                                  id={values && values.id}
                                  name={"mission_image"}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="mission_image" />
                            </Box>
                          </div>
                        </Box>

                        <div className="col-md-12 mt-3">
                          <button
                            type="submit"
                            className="btn btn-primary me-3"
                          >
                            <span className="indicator-label">Save</span>
                          </button>

                          <button type="reset" className="btn btn-light me-3">
                            Cancel
                          </button>
                        </div>
                        {/* </div> */}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default CompanyProfileDetails;
