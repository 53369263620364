// HomePage.js
import React, { useEffect, useState } from "react";
import CompanyProfileDetails from "../components/company-profile-section/company-form";
import axiosInstance from "../hook/axiosInstance";
import * as Yup from "yup";
import { toast } from "react-toastify";

const CompanyProfilePAge = () => {
  const [initialData, setInitialData] = useState();
  const [imageFolderPath, setImageFolderPath] = useState("");

  const initialValues = {
    main_header: "",
    main_content: "",
    main_content_image: null,
    export_content: "",
    export_image: "",
    import_content: "",
    import_image: null,
    vision_content: "",
    vision_image: null,
    mission_content: "",
    mission_image: null,
    created_by: "",
  };

  const validationSchema = Yup.object().shape({
    main_header: Yup.string().required("Main Header is required"),
    main_content: Yup.string().required("Main Content is required"),
    main_content_image: Yup.mixed().required("Main Content Image is required"),
    export_content: Yup.string().required("Export Content is required"),
    export_image: Yup.mixed().required("Export Image is required"),
    import_content: Yup.string().required("Import Content is required"),
    import_image: Yup.mixed().required("Import Image is required"),
    vision_content: Yup.string().required("Vision Content is required"),
    vision_image: Yup.mixed().required("Vision Image is required"),
    mission_content: Yup.string().required("Mission Content is required"),
    mission_image: Yup.mixed().required("Mission Image is required"),
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("companyprofile-get");
      const profileData = response.data; // Assuming the response contains the profile data
      console.log("profileData:", profileData);
      setInitialData(profileData.data[0]);
      setImageFolderPath(profileData?.imgae_folder);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const notify = (msg) => {
    toast.success(msg);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // Create a new FormData object
      let response;

      const formData = new FormData();
      const test = { created_by: 1 };

      const data = { ...values, ...test };

      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (initialData && initialData?.id) {
        // Make the PUT request using axiosInstance
        response = await axiosInstance.post(
          `/companyprofile-save`,
          formData, // Pass the FormData object as the request body
          {
            headers: {
              "Content-Type": "multipart/form-data", // Ensure correct content type
            },
          }
        );
        if (response) {
          notify("Company Profile data saved successfully!");
          fetchData();
        }
      } else {
        // Make the PUT request using axiosInstance
        response = await axiosInstance.post(
          "/companyprofile/save",
          formData, // Pass the FormData object as the request body
          {
            headers: {
              "Content-Type": "multipart/form-data", // Ensure correct content type
            },
          }
        );
        if (response) {
          notify("Company Profile data saved successfully!")
          fetchData();
        }
      }

      // Handle the response as needed
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <CompanyProfileDetails
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      initialData={initialData}
      validationSchema={validationSchema}
      imageFolderPath={imageFolderPath}
    />
  );
};

export default CompanyProfilePAge;
