import React from "react";
import { Formik, Form, Field } from "formik";
import { TextEditor } from "../text-editor";

export const CommonForm = ({handleSubmit,initialValues}) => {
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid"  style={{overflowY:"scroll"}}>
    <div
      id="kt_app_content_container"
      className="app-container container-xxl"
      style={{overflowY:"scroll"}}
    >
      <div className="row gx-5 gx-xl-12 pt-10">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body py-4">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Form
                    id="kt_ecommerce_settings_general_form"
                    className="form"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label">Dropdown</label>
                        <Field
                          as="select"
                          name="language"
                          aria-label="Select a Language"
                          data-control="select2"
                          data-placeholder="Choose..."
                          className="form-select mb-2"
                        >
                          <option></option>
                          <option value="Akash sharma">
                            Akash sharma
                          </option>
                          <option value="Indra vijayl">
                            Indra vijayl
                          </option>
                          <option value="Other">Other</option>
                        </Field>
                      </div>

                      <div className="col-md-6">
                        <label className="fs-6 fw-semibold form-label">
                          <span>Text Field</span>
                        </label>
                        <Field
                          type="text"
                          name="textField"
                          className="form-control"
                        />
                      </div>

                      <div className="col-md-12">
                        <label className="fs-6 fw-semibold form-label" >
                          <span>
                            Text Area (It should be Text Editor)
                          </span>
                        </label>
                        <Field  name="desc" component={TextEditor} />
                      </div>

                      <div className="col-md-6" style={{paddingTop:100}}>
                        <label className="fs-6 fw-semibold form-label">
                          <span>Photo Upload</span>
                        </label>
                        <Field
                          type="file"
                          name="photo"
                          className="form-control"
                        />
                      </div>

                      <div className="col-md-12 mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary me-3"
                        >
                          <span className="indicator-label">
                            Save
                          </span>
                        </button>

                        <button
                          type="reset"
                          className="btn btn-light me-3"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div
        className="tab-pane fade show"
        id="kt_contact_tab"
        role="tabpanel"
      >
        <div className="card mb-6 mb-xl-9">
          <div className="card-body pt-0 pb-2">
            <table
              id="kt_profile_overview_table1"
              className="table align-middle table-row-dashed gy-5"
            >
              <tbody className="fs-6 text-black-400">
                <tr>
                  <td width="95%">Welcome to Maroo Steels</td>
                  <td width="5%">
                    <a hrf="#">
                      <i className="text-black bi bi-pencil"></i>
                    </a>
                    &nbsp;&nbsp;
                  </td>
                </tr>

                <tr>
                  <td width="95%">
                    Lorem Ipsum is simply dummy text of the printing
                    and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the
                    1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen
                    book. It has survived not only five centuries, but
                    also the leap into electronic typesetting,
                    remaining essentially unchanged. It was
                    popularised in the 1960s with the release of
                    Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem
                    Ipsum.
                  </td>
                  <td width="5%">
                    <a hrf="#">
                      <i className="text-black bi bi-pencil"></i>
                    </a>
                    &nbsp;&nbsp;
                  </td>
                </tr>

                <tr>
                  <td width="95%">
                    <img src="https://fastly.picsum.photos/id/228/200/300.jpg?hmac=A6oUMz6rMYY00q0GJfUDf_sU2uPTrqbUHVQykZQtZDU" />
                  </td>
                  <td width="5%">
                    <a hrf="#">
                      <i className="text-black bi bi-pencil"></i>
                    </a>
                    &nbsp;&nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

