import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import ImageUpload from "../components/common-form/image-common";
import axiosInstance from "../hook/axiosInstance";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const notify = (msg) => {
  toast.success(msg);
};

const ContactPage = () => {
  const [editModal, setEditModal] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [handleOpenAddModal, setHandleOpenAddModal] = useState(false);
  const initialValues = {
    full_name: "",
    designation: "",
    email: "",
    mobile_1: "",
    mobile_2: "",
    created_by: 1,
    profile_image: "",
  };

  const addinitalValues = {
    full_name: "",
    designation: "",
    email: "",
    mobile_1: "",
    mobile_2: "",
    created_by: 1,
    profile_image: "",
  };
  
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full Name is required"),
    designation: Yup.string().required("Designation is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile_1: Yup.string().required("Mobile 1 is required"),
    mobile_2: Yup.string().required("Mobile 2 is required"),
    profile_image: Yup.mixed().required("Profile Image is required"),
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/contactus-get");
      const profileData = response.data;
      setInitialData(profileData.data);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditItemId(null)
  };

  const handleEditButtonClick = (id) => {
    setEditItemId(id);
    setEditModal(true);
  };



  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      const test = { created_by:1};

      const data = { ...values, ...test };
      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      let response;
      // if (editItemId && editItemId?.id) {
      //   response = await axiosInstance.put(
      //     `/contactus-save`,
      //     data,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data", // Ensure correct content type
      //       },
      //     }
      //   );
      // } else {
        response = await axiosInstance.post(`/contactus-save`, data, {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct content type
          },
        });
      // }

      if (response) {
        notify("Contact-us data saved successfully")
        fetchData();
        resetForm()
        setEditItemId(null)
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setEditModal(false);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 150 },

    { field: "full_name", headerName: "Full Name", width: 150 },
    { field: "designation", headerName: "Designation", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "mobile_1", headerName: "Mobile 1", width: 150 },
    { field: "mobile_2", headerName: "Mobile 2", width: 150 },
    {
      field: "profile_image",
      headerName: "Profile Image",
      width: 150,
      renderCell: (params) =>
        // <ImageUpload
        //   id={params.id}
        //   name={"aluminiumn_coil_image"}
        //   value={params.value}
        //   onChange={() => {}}
        // />
        params?.profile_image,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEditButtonClick(params.row)}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Box mt={10} height={400} width="100%">
      <Button
        variant="contained"
        sx={{ my: 2 }}
        onClick={() => {setEditModal(true)}}
      >
        {" "}
        Add Contact
      </Button>
      <DataGrid
        rows={initialData && initialData?.length > 0 ? initialData : []}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
      />
      <Dialog
        open={editModal}
        maxWidth="sm"
        fullWidth
        onClose={handleEditModalClose}
      >
        <DialogTitle>Edit Contact US</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItemId?.id ? editItemId : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              errors,
            }) => 
            
           {
            console.log("errors",errors) 
            return(
              <Form onSubmit={handleSubmit}>
                <Box>
                  <Typography variant="h5" gutterBottom>
                    {/* User Information */}
                  </Typography>
                  <Box mt={2}>
                    <Field
                      name="full_name"
                      as={TextField}
                      label="Full Name"
                      fullWidth
                      value={values.full_name}
                      onChange={handleChange}
                      error={Boolean(errors.full_name)}
                      helperText={errors.full_name}
                    />
                  </Box>
                  <Box mt={2}>
                    <Field
                      name="designation"
                      as={TextField}
                      label="Designation"
                      fullWidth
                      value={values.designation}
                      onChange={handleChange}
                      error={Boolean(errors.designation)}
                      helperText={errors.designation}
                    />
                  </Box>
                  <Box mt={2}>
                    <Field
                      name="email"
                      as={TextField}
                      type="email"
                      label="Email"
                      fullWidth
                      value={values.email}
                      onChange={handleChange}
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                    />
                  </Box>
                  <Box mt={2}>
                    <Field
                      name="mobile_1"
                      as={TextField}
                      label="Mobile 1"
                      fullWidth
                      value={values.mobile_1}
                      onChange={handleChange}
                      error={Boolean(errors.mobile_1)}
                      helperText={errors.mobile_1}
                    />
                  </Box>
                  <Box mt={2}>
                    <Field
                      name="mobile_2"
                      as={TextField}
                      label="Mobile 2"
                      fullWidth
                      value={values.mobile_2}
                      onChange={handleChange}
                      error={Boolean(errors.mobile_2)}
                      helperText={errors.mobile_2}
                    />
                  </Box>
                  <Box mt={2}>
                    <Typography fontWeight={600} sx={{ my: 1 }}>
                      Profile Image
                    </Typography>
                    <Field
                      name="profile_image"
                      label="Upload Image"
                      render={({ field, form }) => (
                        <ImageUpload
                          id={values && values.id}
                          name={"profile_image"}
                          value={field.value}
                          imageFolderPath="contact_profile_pic"
                          onChange={setFieldValue}
                        />
                      )}
                      error={Boolean(errors.profile_image)}
                      helperText={errors.profile_image}
                    />
                    <Box mt={2}>

                    </Box>
                  </Box>{" "}
                  <Box
                    mt={2}
                    display={"flex"}
                    justifyContent="end"
                    flexDirection={"row-reverse"}
                    gap={2}
                  >
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                    <Button
                      sx={{ ml: 2 }}
                      type="submit"
                      variant="contained"
                      color="error"
                      onClick={handleEditModalClose}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ContactPage;
