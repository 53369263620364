import React, { useEffect, useState } from "react";
import ExportDetailsPage from "../components/export-details";
import axiosInstance from "../hook/axiosInstance";
import * as Yup from "yup";
import ExportPageBanner from "../components/export-details/export-page-banner";
import { toast } from "react-toastify";

const notify = (msg) => {
  toast.success(msg);
};


const ExportPage = () => {
  const [initialData, setInitialData] = useState();
  const [imageFolderPath, setImageFolderPath] = useState("");

  const initialValues = {
    hot_rolled: "",
    hot_rolled_image: "",
    cold_rolled: "",
    cold_rolled_image: "",
    hot_dipped: "",
    hot_dipped_image: "",
    aluzinc_steel: "",
    aluzinc_steel_image: "",
    prepainted: "",
    prepainted_image: "",
    color_coated: "",
    color_coated_image: "",
    bare_aluminium: "",
    bare_aluminium_image: "",
    aluminiumn_coil: "",
    aluminiumn_coil_image: "",
    aluminium: "",
    aluminium_image: "",
    zinc_aluminum:"",
    zinc_aluminum_image:"",
    wire_rods: "",
    wire_rods_image: "",
    wire_products: "",
    wire_products_image: "",
    structural_steel: "",
    structural_steel_image: "",
    pipe_products: "",
    pipe_products_image: "",
    hot_rolled_round: "",
    hot_rolled_round_image: "",
    stainless_steel: "",
    stainless_steel_image: "",
    ferro_alloys: "",
    ferro_alloys_image: "",
    created_by: 1,
  };

  const validationSchema = Yup.object().shape({
    hot_rolled: Yup.string().required("Hot Rolled is required"),
    hot_rolled_image: Yup.string().required("Hot Rolled Image is required"),
    cold_rolled: Yup.string().required("Cold Rolled is required"),
    cold_rolled_image: Yup.string().required("Cold Rolled Image is required"),
    hot_dipped: Yup.string().required("Hot Dipped is required"),
    hot_dipped_image: Yup.string().required("Hot Dipped Image is required"),
    aluzinc_steel: Yup.string().required("Aluzinc Steel is required"),
    aluzinc_steel_image: Yup.string().required(
      "Aluzinc Steel Image is required"
    ),
    prepainted: Yup.string().required("Prepainted is required"),
    prepainted_image: Yup.string().required("Prepainted Image is required"),
    color_coated: Yup.string().required("Color Coated is required"),
    color_coated_image: Yup.string().required("Color Coated Image is required"),
    bare_aluminium: Yup.string().required("Bare Aluminium is required"),
    bare_aluminium_image: Yup.string().required(
      "Bare Aluminium Image is required"
    ),
    aluminiumn_coil: Yup.string().required("Aluminiumn Coil is required"),
    aluminiumn_coil_image: Yup.string().required(
      "Aluminiumn Coil Image is required"
    ),
    aluminium: Yup.string().required("Aluminium is required"),
    aluminium_image: Yup.string().required("Aluminium Image is required"),
    wire_rods: Yup.string().required("Wire Rods is required"),
    wire_rods_image: Yup.string().required("Wire Rods Image is required"),
    wire_products: Yup.string().required("Wire Products is required"),
    wire_products_image: Yup.string().required(
      "Wire Products Image is required"
    ),
    structural_steel: Yup.string().required("Structural Steel is required"),
    structural_steel_image: Yup.string().required(
      "Structural Steel Image is required"
    ),
    pipe_products: Yup.string().required("Pipe Products is required"),
    pipe_products_image: Yup.string().required(
      "Pipe Products Image is required"
    ),
    hot_rolled_round: Yup.string().required("Hot Rolled Round is required"),
    hot_rolled_round_image: Yup.string().required(
      "Hot Rolled Round Image is required"
    ),
    stainless_steel: Yup.string().required("Stainless Steel is required"),
    stainless_steel_image: Yup.string().required(
      "Stainless Steel Image is required"
    ),
    ferro_alloys: Yup.string().required("Ferro Alloys is required"),
    ferro_alloys_image: Yup.string().required("Ferro Alloys Image is required"),
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("exportdata-get");

      const profileData = response.data;
      console.log("profileData:", profileData);
      setInitialData(profileData.data[0]);
      setImageFolderPath(profileData?.image_folder);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      const test = { created_by: 1 };
      delete values?.created_at;
      const data = { ...values, ...test };

      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      let response;

      // if (initialData && initialData?.id) {
      //   response = await axiosInstance.put(
      //     `/export/update/${initialData?.id}`,
      //     data,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data", // Ensure correct content type
      //       },
      //     }
      //   );
      // } else {
        response = await axiosInstance.post("/exportdata-save", data, {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct content type
          },
        });
      // }

      if (response) {
        notify("Export data saved successfully!")
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <ExportPageBanner imageFolderPath={imageFolderPath}/>
      <ExportDetailsPage
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        initialData={initialData}
        validationSchema={validationSchema}
        imageFolderPath={imageFolderPath}
      />
    </>
  );
};
export default ExportPage;
