

import axios from 'axios';

const token = sessionStorage.getItem('token');

// Check if token is available
if (!token) {
  // Handle the case where token is not available
  console.error('Token not found in sessionStorage');
}


const apiKey = process.env.REACT_APP_API_KEY;

const axiosInstanceWithUrlencoded = axios.create({
  baseURL: apiKey,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    'Authorization': `Bearer ${token}`
  }
});

// Add a response interceptor
axiosInstanceWithUrlencoded.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Check if the error response contains "Invalid token" message
  if (error.response && error.response.data && error.response.data.message === "Invalid token") {
    // Navigate to the home page
    sessionStorage.removeItem("token")
    window.location.href = '/login'; // Replace '/' with the actual URL of your home page
  }
  return Promise.reject(error);
});

export default axiosInstanceWithUrlencoded;