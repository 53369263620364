import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  Chat as ChatIcon,
  Image,
} from "@mui/icons-material";
import Header from "../header";
import { Avatar, MenuItem, Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 265;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Sidebar({ children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState(null);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAccordionChange = (index) => {
    setExpanded((prevExpanded) => (prevExpanded === index ? null : index));
  };

  const sideBarPageData = [
    {
      // icon: <DashboardIcon />,
      primary: "Dashboards",
      subItems: [],
    },
    {
      // icon: <DashboardIcon />,
      primary: "Home",
      subItems: [],
    },
    {
      // icon: <PersonIcon />,
      primary: "About Us",
      subItems: [
        "Company-Profile",
        "Chairman's-Note",
        "Current-Business",
        "History",
        "Team",
        // "Vision-Mission",
      ],
    },
    {
      // icon: <PersonIcon />,
      primary: "Product and Service",
      subItems: ["Import", "Export", "Service"],
    },
    {
      // icon: <PersonIcon />,
      primary: "Gallery",
      subItems: ["Photos", "Videos"],
    },
    {
      // icon: <PersonIcon />,
      primary: "Contact Us",
      subItems: ["Contact", "Support", "Careers"],
    },
    // {
    //   icon: <ChatIcon />,
    //   primary: "Enquiry",
    //   subItems: [],
    // },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [seleceteRoute, setSelectedRoute] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [routerName, setRouterName] = useState("");

  useEffect(() => {
    const routes = localStorage.getItem("route");
    setSelectedRoute(routes);
  }, []);
  // Function to close the user menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (item) => {
    if (item && item === "Dashboards") {
      navigate("/");
      setSelectedRoute(item);
    } else {
      const selectedMenu = item.trim().toLowerCase();
      localStorage.setItem("route", selectedMenu);
      navigate(`${selectedMenu}`);
      setSelectedRoute(item);
    }
  };
  const test = {
    color: "#fff",
    backgroundColor: "#2a2a3c",

    // border:"px solid black",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#3a3a4c", // Adjust this to your desired hover color
    },
  };

  const hanldeSignOut = () => {
    sessionStorage.clear();

    // Remove token (assuming it's stored as 'token' in session storage)
    sessionStorage.removeItem("token");

    // Navigate to the home page
    window.location.href = "/"; // Replace '/' with the actual URL of your home page
  };

  const location = useLocation();

  useEffect(() => {
    const routerName = localStorage?.getItem("route");
    console.log('routerName:', routerName);
    setRouterName(routerName);
  }, [location]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFF",
          }}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, color: "#000", ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{ textTransform: "capitalize" }}
              noWrap
              component="div"
              color="#000"
            >
              {routerName && routerName}
            </Typography>
          </Box>
          <div>
            <Avatar
              alt="User"
              src=""
              variant="square"
              sx={{ borderRadius: 2 }}
              onMouseEnter={handleClick}
              onClick={handleClick}
            />
          </div>
          <Popover
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            marginThreshold={47}
            elevation={2}
            slotProps={{
              paper: {
                sx: {
                  mt: 2.7,
                  width: 300,
                  height: 180,
                },
              },
            }}
            TransitionProps={{ timeout: 400 }}
          >
            <Box onMouseLeave={handleClose}>
              <MenuItem sx={{ px: 2, py: 2 }}>
                <Avatar
                  alt="Admin"
                  variant="square"
                  sx={{ borderRadius: 2, height: 60, width: 60 }}
                  src=""
                />
                <Typography pl={2} fontWeight={600} fontSize={18}>
                  Admin
                </Typography>
              </MenuItem>
              <MenuItem onClick={hanldeSignOut}>
                <Typography fontWeight={600} fontSize={14}>
                  Sign Out
                </Typography>
              </MenuItem>
            </Box>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#ccced0",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img
            srcSet={`assets/img/login-logo.png`}
            src={`assets/img/login-logo.png`}
            alt={"logo"}
            loading="lazy"
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          {/* <Header
            // isMobileDrawerOpen={isMobileDrawerOpen}
            // setIsMobileDrawerOpen={setIsMobileDrawerOpen}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
          /> */}
        </DrawerHeader>
        <Divider />

        <List sx={{ backgroundColor: "#ccced0 !important", px: 2 }}>
          {sideBarPageData.map((item, index) => (
            <React.Fragment key={index}>
              {item.subItems.length > 0 ? (
                <Accordion
                  sx={{ backgroundColor: "#ccced0", boxShadow: "none" }}
                  expanded={expanded === index}
                  onChange={() => handleAccordionChange(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${index}`}
                    id={`panel-${index}`}
                  >
                    {/* <ListItemIcon sx={{ color: "#000" }}>
                      {item.icon}
                    </ListItemIcon> */}
                    <ListItemText primary={item.primary} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {item.subItems.map((subItem, subIndex) => (
                        <ListItemButton
                          key={subIndex}
                          onClick={() => handleClickMenu(subItem, subIndex)}
                          sx={
                            seleceteRoute?.toLowerCase() ===
                            subItem.toLowerCase()
                              ? test
                              : ""
                          }
                        >
                          {/* <ListItemIcon
                            sx={{
                              color:
                                seleceteRoute?.toLowerCase() ===
                                subItem.toLowerCase()
                                  ? "#fff"
                                  : "#000",
                            }}
                          >
                            {subIndex % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                          </ListItemIcon> */}
                          <ListItemText
                            primary={subItem.split("-").join(" ")}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <ListItemButton
                  onClick={() => handleClickMenu(item.primary)}
                  sx={
                    seleceteRoute?.toLowerCase() === item.primary?.toLowerCase()
                      ? test
                      : ""
                  }
                >
                  {/* <ListItemIcon
                    sx={{
                      
                      color:
                        seleceteRoute?.toLowerCase() ===
                        item.primary.toLowerCase()
                          ? "#fff"
                          : "#000",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon> */}
                  <ListItemText primary={item.primary} />
                </ListItemButton>
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        {/* <DrawerHeader /> */}

        {children}
      </Main>
    </Box>
  );
}
