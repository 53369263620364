// HomePage.js
import React, { useEffect, useState } from "react";
import axiosInstance from "../hook/axiosInstance";
import ImportDetailsPage from "../components/import-details";
import HomePageDetails from "../components/home-page-details";
import * as Yup from "yup";
import HomePageBanner from "../components/home-page-details/home-page-banner";
import { Button } from "@mui/material";
import Loader from "../components/loader";
import { toast } from 'react-toastify';


const HomePage = () => {
  const [initialData, setInitialData] = useState();
  const [imageFolderPath, setImageFolderPath] = useState("");
  const [isLoading, setLoading] = useState(false);

  const initialValues = {
    about_us: "",
    about_us_video: "",
    service: "",
    service_image: "",
    import_home: "",
    import_home_img: "",
    export_home: "",
    export_home_img: "",
    created_by: 1,
  };

  const validationSchema = Yup.object().shape({
    about_us: Yup.string().required("About us is required"),
    about_us_video: Yup.string().required("About us video is required"),
    service: Yup.string().required("Service is required"),
    service_image: Yup.mixed().required("Service image is required"),
    import_home: Yup.string().required("Import home is required"),
    import_home_img: Yup.mixed().required("Import home image is required"),
    export_home: Yup.string().required("Export home is required"),
    export_home_img: Yup.mixed().required("Export home image is required"),
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("home-get");
      const profileData = response.data;
      setImageFolderPath(profileData.imgae_folder);
      setInitialData(profileData.data[0]);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const notify = (msg) => {
    toast.success(msg);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);

      const formData = new FormData();
      const test = { created_by: 1 };

      const data = { ...values, ...test };
      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      // if (initialData && initialData?.id) {
      //   const response = await axiosInstance.put(
      //     `/homepage/update/${initialData?.id}`,
      //     data,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data", // Ensure correct content type
      //       },
      //     }
      //   );
      //   if (response) {
      //     fetchData();
      //   }
      // } else {
      const response = await axiosInstance.post("/home-save", data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure correct content type
        },
      });
      if (response) {
        notify("Home Page Data Saved Successfully!")
        fetchData("");  
        resetForm();
      }
      // }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* {isLoading && <Loader />} */}
      {!isLoading && (
        <>
          <HomePageBanner imageFolderPath={imageFolderPath} />
          <HomePageDetails
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            initialData={initialData}
            validationSchema={validationSchema}
            imageFolderPath={imageFolderPath}
          />
        </>
      )}
    </>
  );
};

export default HomePage;
