import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextEditor } from "../text-editor";
import { Box, Typography } from "@mui/material";
import ImageUpload from "../common-form/image-common";

const ImportDetailsPage = ({
  handleSubmit,
  initialValues,
  initialData,
  validationSchema,
  imageFolderPath
}) => {
  return (
    <div
      id="kt_app_content"
      className="app-content flex-column-fluid"
      style={{ overflowY: "scroll" }}
    >
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ overflowY: "scroll" }}
      >
        <div className="row gx-5 gx-xl-12 pt-10">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body py-4">
                <Formik
                  initialValues={
                    initialData && initialData?.id ? initialData : initialValues
                  }
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, values }) => {
                    return (
                      <Form
                        id="kt_ecommerce_settings_general_form"
                        className="form"
                      >
                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Semi Product Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="semi_products_column"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="semi_products_column" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Semi Product Image
                            </Typography>
                            <Field
                              name="semiproductimage_column"
                              label="Upload Image"
                              render={({ field, form }) => {
                                return (
                                  <ImageUpload
                                    name={"semiproductimage_column"}
                                    value={values?.semiproductimage}
                                    onChange={setFieldValue}
                                    id={values && values.id}
                                    imageFolderPath={"import_data"}
                                  />
                                );
                              }}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="semiproductimage_column" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            Flat Products Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="flat_products_column"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="flat_products_column" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Flat Products Image
                            </Typography>

                            <Field
                              name="flat_products_image_column"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"flat_products_image_column"}
                                  value={field.value}
                                  onChange={setFieldValue}
                                  imageFolderPath={"import_data"}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="flat_products_image_column" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Coated Steel Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="coated_steel_column"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="coated_steel_column" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Coated Steel Image
                            </Typography>
                            <Field
                              name="coated_steel_image_column"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"coated_steel_image_column"}
                                  value={field.value}
                                  onChange={setFieldValue}
                                  imageFolderPath={"import_data"}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="coated_steel_image_column" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Long Products Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="long_products_column"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="long_products_column" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Long Products Image
                            </Typography>

                            <Field
                              name="long_products_image_column"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"long_products_image_column"}
                                  value={field.value}
                                  onChange={setFieldValue}
                                  imageFolderPath={"import_data"}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="long_products_image_column" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Seamless Pipe Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="seamless_pipe_column"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="seamless_pipe_column" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Seamless Pipe Image
                            </Typography>

                            <Field
                              name="seamless_pipe_image_column"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"seamless_pipe_image_column"}
                                  value={field.value}
                                  onChange={setFieldValue}
                                  imageFolderPath={"import_data"}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="seamless_pipe_image_column" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Secondary Product Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="secondary_products_column"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="secondary_products_column" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Secondary Product Image
                            </Typography>

                            <Field
                              name="secondary_product_image_column"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"secondary_product_image_column"}
                                  value={field.value}
                                  onChange={setFieldValue}
                                  imageFolderPath={"import_data"}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="secondary_product_image_column" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Row Material
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="raw_material_column"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="raw_material_column" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Row Material Image
                            </Typography>

                            <Field
                              name="raw_material_image_column"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"raw_material_image_column"}
                                  value={field.value}
                                  onChange={setFieldValue}
                                  imageFolderPath={"import_data"}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="raw_material_image_column" />
                            </Box>
                          </div>
                        </Box>

                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Scrap
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="scrap_column"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="scrap_column" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Scrap Image
                            </Typography>

                            <Field
                              name="scrap_image_column"
                              label="Upload Image"
                              render={({ field, form }) => (
                                <ImageUpload
                                  id={values && values.id}
                                  name={"scrap_image_column"}
                                  value={field.value}
                                  onChange={setFieldValue}
                                  imageFolderPath={"import_data"}
                                />
                              )}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="scrap_image_column" />
                            </Box>
                          </div>
                        </Box>

                        <div className="col-md-12 mt-3">
                          <button
                            type="submit"
                            className="btn btn-primary me-3"
                          >
                            <span className="indicator-label">Save</span>
                          </button>

                          <button type="reset" className="btn btn-light me-3">
                            Cancel
                          </button>
                        </div>
                        {/* </div> */}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default ImportDetailsPage;
