// HomePage.js
import React from 'react';
import { CommonForm } from '../components/common-form';

const AboutPage = () => {
  const initialValues = {
    language: '',
    textField: '',
    textArea: ' ',
    photo: null,
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission logic here
    

    // Reset the form after successful submission
    resetForm();
  };

  return <CommonForm handleSubmit={handleSubmit} initialValues={initialValues} />;
};

export default AboutPage;
