import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextEditor } from "../text-editor";
import { Box, TextField, Typography } from "@mui/material";
import ImageUpload from "../common-form/image-common";

const TeamPageDetails = ({
  handleSubmit,
  initialValues,
  initialData,
  imageFolderPath,
  validationSchema,
}) => {
  return (
    <div
      id="kt_app_content"
      className="app-content flex-column-fluid"
      style={{ overflowY: "scroll" }}
    >
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ overflowY: "scroll" }}
      >
        <div className="row gx-5 gx-xl-12 pt-10">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body py-4">
                <Formik
                  initialValues={
                    initialData && initialData?.id ? initialData : initialValues
                  }
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({ setFieldValue, values, errors, handleChange }) => {
                    return (
                      <Form
                        id="kt_ecommerce_settings_general_form"
                        className="form"
                      >
                        {/* Main Heading  */}
                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Team Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="content" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Image one
                            </Typography>
                            <Field
                              name="image_1"
                              label="Upload Image"
                              render={({ field, form }) => {
                                return (
                                  <ImageUpload
                                    name={"image_1"}
                                    value={field.value}
                                    onChange={setFieldValue}
                                    id={values && values.id}
                                    imageFolderPath={"team"}
                                  />
                                );
                              }}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="image_1" />
                            </Box>
                          </div>

                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Image Two
                            </Typography>
                            <Field
                              name="image_2"
                              label="Upload Image"
                              render={({ field, form }) => {
                                return (
                                  <ImageUpload
                                    name={"image_2"}
                                    value={field.value}
                                    onChange={setFieldValue}
                                    id={values && values.id}
                                    imageFolderPath={"team"}
                                  />
                                );
                              }}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="image_2" />
                            </Box>
                          </div>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Image Three
                            </Typography>
                            <Field
                              name="image_3"
                              label="Upload Image"
                              render={({ field, form }) => {
                                return (
                                  <ImageUpload
                                    name={"image_3"}
                                    value={field.value}
                                    onChange={setFieldValue}
                                    id={values && values.id}
                                    imageFolderPath={"team"}
                                  />
                                );
                              }}
                            />
                            <Box mt={2} color="red" fontSize={18}>
                              <ErrorMessage name="image_3" />
                            </Box>
                          </div>
                        </Box>
                        <Box>
                          <Box mt={2}>
                            <div
                              className="col-md-6"
                              style={{ paddingTop: 100 }}
                            >
                              <Typography
                                fontStyle="bold"
                                fontWeight={300}
                                fontSize={16}
                              >
                                Image Four
                              </Typography>

                              <Field
                                name="image_4"
                                label="Upload Image"
                                render={({ field, form }) => {
                                  return (
                                    <ImageUpload
                                      name={"image_4"}
                                      value={field.value}
                                      onChange={setFieldValue}
                                      id={values && values.id}
                                      imageFolderPath={"team"}
                                    />
                                  );
                                }}
                              />
                              <Box mt={2} color="red" fontSize={18}>
                                <ErrorMessage name="image_4" />
                              </Box>
                              <Box my={2}>
                                <Typography fontWeight={600} fontSize={18}>
                                  Name
                                </Typography>
                                <Field
                                  name="image_4_name"
                                  as={TextField}
                                  // label="Name"
                                  fullWidth
                                  value={values.image_4_name}
                                  onChange={handleChange}
                                  error={Boolean(errors.designation)}
                                  helperText={errors.designation}
                                />
                                <Box mt={2} color="red" fontSize={18}>
                                  <ErrorMessage name="image_4_name" />
                                </Box>
                              </Box>
                            </div>
                            <Typography fontWeight={600} fontSize={18}>
                              Designation
                            </Typography>

                            <Field
                              name="image_4_desgination"
                              as={TextField}
                              // label="Designation"
                              fullWidth
                              value={values.image_4_desgination}
                              onChange={handleChange}
                              error={Boolean(errors.image_4_desgination)}
                              helperText={errors.image_4_desgination}
                            />

                            {/* <Typography fontWeight={600} fontSize={18}>
                              Image Four Content
                            </Typography>

                            <Field
                              name="image_4_content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            /> */}
                            {/* <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="image_4_content" />
                            </Box> */}
                          </Box>

                          <Box mt={2}>
                            <div
                              className="col-md-6"
                              style={{ paddingTop: 100 }}
                            >
                              <Typography
                                fontStyle="bold"
                                fontWeight={300}
                                fontSize={16}
                              >
                                Image Five
                              </Typography>
                              <Field
                                name="image_5"
                                // label="Upload Image"
                                render={({ field, form }) => {
                                  return (
                                    <ImageUpload
                                      name={"image_5"}
                                      value={field.value}
                                      onChange={setFieldValue}
                                      id={values && values.id}
                                      imageFolderPath={"team"}
                                    />
                                  );
                                }}
                              />
                              <Box mt={2} color="red" fontSize={18}>
                                <ErrorMessage name="image_5" />
                              </Box>
                              <Box mt={2}>
                                <Typography fontWeight={600} fontSize={18}>
                                  Image Five Desgination
                                </Typography>
                                <Field
                                  name="image_5_name"
                                  as={TextField}
                                  // label="Name"
                                  fullWidth
                                  value={values.image_5_name}
                                  onChange={handleChange}
                                  error={Boolean(errors.image_5_name)}
                                  helperText={errors.image_5_name}
                                />
                              </Box>
                            </div>
                            <Typography fontWeight={600} mt={3} fontSize={18}>
                              Designation
                            </Typography>
                            <Field
                              name="image_5_desgination"
                              as={TextField}
                              // label="Designation"
                              fullWidth
                              value={values.image_5_desgination}
                              onChange={handleChange}
                              error={Boolean(errors.image_5_desgination)}
                              helperText={errors.image_5_desgination}
                            />

                            {/* <Typography fontWeight={600} mt={5} fontSize={18}>
                              Image Five Content
                            </Typography>
                            <Field
                              name="image_5_content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            /> */}
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="image_5_content" />
                            </Box>
                          </Box>

                          <Box mt={2}>
                            <div
                              className="col-md-6"
                              style={{ paddingTop: 100 }}
                            >
                              <Typography
                                fontStyle="bold"
                                fontWeight={300}
                                fontSize={16}
                              >
                                Image Six
                              </Typography>
                              <Field
                                name="image_6"
                                // label="Upload Image"
                                render={({ field, form }) => {
                                  return (
                                    <ImageUpload
                                      name={"image_6"}
                                      value={field.value}
                                      onChange={setFieldValue}
                                      id={values && values.id}
                                      imageFolderPath={"team"}
                                    />
                                  );
                                }}
                              />
                              <Box mt={7} color="red" fontSize={18}>
                                <ErrorMessage name="image_6" />
                              </Box>
                              <Box mt={2}>
                                <Typography fontWeight={600} fontSize={18}>
                                  Name
                                </Typography>
                                <Field
                                  name="image_6_name"
                                  as={TextField}
                                  // label="Name"
                                  fullWidth
                                  value={values.image_6_name}
                                  onChange={handleChange}
                                  error={Boolean(errors.designation)}
                                  helperText={errors.designation}
                                />
                              </Box>
                            </div>
                            <Typography fontWeight={600} mt={2} fontSize={18}>
                              Designation
                            </Typography>
                            <Field
                              name="image_6_desgination"
                              as={TextField}
                              // label="Designation"
                              fullWidth
                              value={values.image_6_desgination}
                              onChange={handleChange}
                              error={Boolean(errors.image_6_desgination)}
                              helperText={errors.image_6_desgination}
                            />
                            {/* <Typography fontWeight={600} mt={2} fontSize={18}>
                              Image six Content
                            </Typography>
                            <Field
                              name="image_6_content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red" fontSize={18}>
                              <ErrorMessage name="image_6_content" />
                            </Box> */}
                          </Box>
                        </Box>

                        <Box mt={10}>
                          <div className="col-md-12 mt-3">
                            <button
                              type="submit"
                              className="btn btn-primary me-3"
                            >
                              <span className="indicator-label">Save</span>
                            </button>

                            <button type="reset" className="btn btn-light me-3">
                              Cancel
                            </button>
                          </div>
                        </Box>
                        {/* </div> */}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default TeamPageDetails;
