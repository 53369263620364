// HomePage.js
import React, { Fragment, useEffect, useState } from "react";
import { CommonForm } from "../components/common-form";
import ChairMainNotesDetails from "../components/chairman-notes-detail";
import axiosInstance from "../hook/axiosInstance";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChairmanNotesPage = () => {
  const [initialData, setInitialData] = useState();
  const [imageFolderPath, setImageFolderPath] = useState("");

  const initialValues = {
    image: "",
    content: "",
    created_by: "",
  };

  const validationSchema = Yup.object().shape({
    image: Yup.string().required("Image is required"),
    content: Yup.string().required("Content is required"),
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("ceonote-get");
      const profileData = response.data; // Assuming the response contains the profile data
      console.log("profileData:", profileData);
      setImageFolderPath("uploads/ceo");
      setInitialData(profileData.data[0]);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const notify = (msg) => {
    toast.success(msg);
  };
  

  const handleSubmit = async (values, { resetForm }) => {

    try {
      const formData = new FormData();
      const test = { created_by:1};

      const data = { ...values, ...test };
      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // if (initialData && initialData?.id) {
      //   const response = await axiosInstance.put(
      //     `/ceo/save`,
      //     data,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //     }
      //   );
      //   if (response) {
      //     fetchData();
      //   }
      // } else {
        const response = await axiosInstance.post("ceonote-save", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response) {
          notify("Ceo note saved successfully!")
          fetchData();
          }
        // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <ChairMainNotesDetails
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      initialData={initialData}
      validationSchema={validationSchema}
      imageFolderPath={imageFolderPath}
    />
  );
};

export default ChairmanNotesPage;
