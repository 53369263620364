import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextEditor } from "../text-editor";
import { Box, Typography } from "@mui/material";
import ImageUpload from "../common-form/image-common";

const ChairMainNotesDetails = ({
  handleSubmit,
  initialValues,
  initialData,
  validationSchema,
  imageFolderPath
}) => {
  return (
    <div
      id="kt_app_content"
      className="app-content flex-column-fluid"
      style={{ overflowY: "scroll" }}
    >
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ overflowY: "scroll" }}
      >
        <div className="row gx-5 gx-xl-12 pt-10">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body py-4">
                <Formik
                  initialValues={
                    initialData && initialData?.id ? initialData : initialValues
                  }
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({ setFieldValue, values }) => {
                    return (
                      <Form
                        id="kt_ecommerce_settings_general_form"
                        className="form"
                      >
                        {/* Main Heading  */}
                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Chairmain Heading
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                              <Box mt={7} color="red">
                                <ErrorMessage name="content" />
                              </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 100 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              ChairMain image
                            </Typography>
                            <Field
                              name="image"
                              label="Upload Image"
                              render={({ field, form }) => {
                                return (
                                  <ImageUpload
                                    name={"image"}
                                    value={field.value}
                                    onChange={setFieldValue}
                                    id={values && values.id}
                                    imageFolderPath={"ceonote"}
                                  />
                                );
                              }}
                            />
                             <Box mt={2} color="red">
                                <ErrorMessage name="image" />
                              </Box>
                          </div>
                        </Box>

                        <div className="col-md-12 mt-3">
                          <button
                            type="submit"
                            className="btn btn-primary me-3"
                          >
                            <span className="indicator-label">Save</span>
                          </button>

                          <button type="reset" className="btn btn-light me-3">
                            Cancel
                          </button>
                        </div>
                        {/* </div> */}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default ChairMainNotesDetails;
