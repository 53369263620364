import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

const VideosUpload = ({ name, value, onChange,imageFolderPath, id }) => {
  const [video, setVideo] = useState(null);

  useEffect(() => {
    if (value && id) {
      // Assuming you have a similar environment variable for video API URL
      const apiurl = process.env.REACT_APP_IMAGE_KEY;
      const videoPath = `${apiurl}/${imageFolderPath}/${value}`;
      setVideo(videoPath);
    }
  }, [value, id]);

  const handleChange = (event) => {
    const file = event.currentTarget.files[0];
    onChange(name, file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setVideo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Stack direction="row" gap={10}>
      <Box>
        <input type="file" accept="video/*" onChange={handleChange} />
      </Box>
      <Box alignItems="center">
        {video && (
          <div>
            {/* Assuming you want to render video using HTML5 video element */}
            <video controls style={{ maxWidth: '200px' }}>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </Box>
    </Stack>
  );
};

export default VideosUpload;
