// HomePage.js
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import axiosInstance from "../../hook/axiosInstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextEditor } from "../text-editor";
import ImageUpload from "../common-form/image-common";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import VideosUpload from "../common-form/video-common";
import { toast } from "react-toastify";

const notify = (msg) => {
  toast.success(msg);
};

const ImportPageBanner = ({ imageFolderPath }) => {
  const apiurl = process.env.REACT_APP_IMAGE_KEY;
  const [initialData, setInitialData] = useState();
  const initialValues = {
    image: "",
    content: "",
    created_by: "",
  };

  const editInitialValues = {
    created_by: "",
    image: "",
    content: "",
  };

  const [clearImage, setClearImage] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const validationSchema = Yup.object().shape({
    image: Yup.string().required("Image is required"),
    content: Yup.string().required("Content is required"),
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("importbanner-get");
      const profileData = response.data; // Assuming the response contains the profile data
      console.log("profileData:", profileData);
      setInitialData(profileData.data);
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleEditModalClose = () => {
    setEditModal(false);
  };

  const handleAddModalClose = () => {
    setAddModal(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      const test = { created_by: 1 };

      const data = { ...values, ...test };
      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // if (initialData && initialData?.id) {
      //   const response = await axiosInstance.put(
      //     `/import/update/${initialData?.id}`,
      //     data,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data", // Ensure correct content type
      //       },
      //     }
      //   );
      //   if (response) {
      //     fetchData();
      //   }
      // } else {
      const response = await axiosInstance.post("/importbanner-save ", data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure correct content type
        },
      });
      if (response) {
        notify("Imort banner data saved successfully!")
        fetchData();
        setClearImage(true)
      }
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      const test = { created_by:1};

      const data = { ...values, ...test };
      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axiosInstance.post(`/importbanner-save`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure correct content type
        },
      });
      if (response) {
        notify("Imort banner data edited successfully!")
        fetchData();
        setClearImage(true)
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setEditModal(false);
    }
  };
  const handleEdit = (id) => {
    // Open modal or navigate to edit page with the selected item's ID
    setEditItemId(id);
    setEditModal(true);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "content",
      headerName: "Content",
      width: 200,
      renderCell: (params) => 
      (
        <div
          dangerouslySetInnerHTML={{
            __html: params?.row.content,
          }}
        />
        
      ),
    },

    // { field: "material_type", headerName: "Material Type", width: 150 },
    // { field: "created_by", headerName: "Created By", width: 150 },
    {
      field: "image",
      headerName: "Image",
      width: 200,
      renderCell: (params) => {
        const imagePath = `${apiurl}/import_banner/${params?.value}`;
        return (
          <img src={imagePath} alt="Item" height={"50px"} width={"50px"} />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>
          {/* <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button> */}
        </>
      ),
    },
  ];

  return (
    <div
      id="kt_app_content"
      className="app-content flex-column-fluid"
      style={{ overflowY: "scroll" }}
    >
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ overflowY: "scroll" }}
      >
        <div className="row gx-5 gx-xl-12 pt-10">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body py-4">
                <Formik
                  initialValues={
                    initialData && initialData?.id ? initialData : initialValues
                  }
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({ setFieldValue, values }) => {
                    return (
                      <Form
                        id="kt_ecommerce_settings_general_form"
                        className="form"
                      >
                        {/* Main Heading  */}
                        <Box mt={10}>
                          <Typography
                            fontStyle="bold"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            Import Page Banner Content
                          </Typography>
                          <Box mt={2}>
                            <Field
                              name="content"
                              render={({ field, form }) => (
                                <TextEditor
                                  name={field.name}
                                  value={field.value}
                                  onChange={setFieldValue}
                                />
                              )}
                            />
                            <Box mt={7} color="red">
                              <ErrorMessage name="content" />
                            </Box>
                          </Box>
                          <div className="col-md-6" style={{ paddingTop: 20 }}>
                            <Typography
                              fontStyle="bold"
                              fontWeight={300}
                              fontSize={16}
                            >
                              Import Page Banner Image
                            </Typography>
                            <Field
                              name="image"
                              label="Upload Image"
                              render={({ field, form }) => {
                                return (
                                  <ImageUpload
                                   clearImage={clearImage}
                                    name={"image"}
                                    value={field.value}
                                    onChange={setFieldValue}
                                    id={values && values.id}
                                    imageFolderPath={"import_banner"}
                                  />
                                );
                              }}
                            />
                            <Box mt={2} color="red">
                              <ErrorMessage name="image" />
                            </Box>
                          </div>
                        </Box>

                        <div className="col-md-12 mt-3">
                          <button
                            type="submit"
                            className="btn btn-primary me-3"
                          >
                            <span className="indicator-label">Save</span>
                          </button>

                          <button type="reset" className="btn btn-light me-3">
                            Cancel
                          </button>
                        </div>
                        {/* </div> */}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <Box mt={3} mb={3} style={{ height: 400 }}>
            <Typography fontWeight={600} variant="h5">
              All Import Page Banner
            </Typography>
            <DataGrid
              rows={initialData && initialData.length > 0 ? initialData : []}
              columns={columns}
              pageSize={10}
            />
          </Box>
        </div>
        <br />
        <br />
      </div>

      <Dialog
        open={editModal}
        maxWidth="md"
        fullWidth
        onClose={handleEditModalClose}
      >
        <DialogTitle>Edit Import Page Banner Data</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={editItemId?.id ? editItemId : editInitialValues}
            enableReinitialize={true}
            onSubmit={handleEditSubmit}
          >
            {({ setFieldValue, errors, handleChange, values }) => (
              <Form>
                {/* Material Type Select */}

                <Box mt={5}>
                  <Typography fontStyle="bold" fontWeight={600} fontSize={16}>
                    Material Name
                  </Typography>
                  <Box mt={2}>
                    <Field
                      name="content"
                      render={({ field }) => (
                        <TextEditor
                          name={field.name}
                          value={field.value}
                          onChange={setFieldValue}
                        />
                      )}
                    />
                  </Box>
                </Box>

                {/* Image upload */}

                <Box mt={10}>
                  <Typography
                    fontStyle="bold"
                    fontWeight={600}
                    fontSize={16}
                    mb={1}
                  >
                    Upload Image
                  </Typography>

                  <Field
                    name="image"
                    label="Upload Image"
                    render={({ field, form }) => {
                      return (
                        <ImageUpload
                        clearImage={clearImage}
                          name={"image"}
                          value={field.value}
                          onChange={setFieldValue}
                          id={values && values.id}
                          imageFolderPath={"import_banner"}
                        />
                      );
                    }}
                  />
                  <Box mt={2} color="red">
                    <ErrorMessage name="image" />
                  </Box>
                </Box>

                <div className="col-md-12 mt-15">
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn btn-primary me-3"
                  >
                    <span className="indicator-label">Save</span>
                  </Button>

                  <Button
                    type="reset"
                    variant="contained"
                    color="error"
                    onClick={() => setEditModal(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImportPageBanner;
