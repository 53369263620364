import React, { useEffect, useState } from "react";
import axiosInstance from "../hook/axiosInstance";
import ImportDetailsPage from "../components/import-details";
import * as Yup from "yup";
import ImportPageBanner from "../components/import-details/import-page-banner";
import { toast } from "react-toastify";

const notify = (msg) => {
  toast.success(msg);
};

const ImportPage = () => {
  const [initialData, setInitialData] = useState();
  const [imageFolderPath,setImageFolderPath]=useState("")
  const initialValues = {
    semi_products_column: "",
    semiproductimage_column: "",
    flat_products_column: "",
    flat_products_image_column: "",
    coated_steel_column: "",
    coated_steel_image_column: "",
    long_products_column: "",
    long_products_image_column: "",
    seamless_pipe_column: "",
    seamless_pipe_image_column: "",
    secondary_products_column: "",
    secondary_product_image_column: "",
    raw_material_column: "",
    raw_material_image_column: "",
    scrap_column: "",
    scrap_image_column: "",
    created_by: 1,
  };

  const validationSchema = Yup.object().shape({
    semi_products_column: Yup.string().required(
      "Semi Products Column is required"
    ),
    semiproductimage_column: Yup.string().required(
      "Semi Product Image Column is required"
    ),
    flat_products_column: Yup.string().required(
      "Flat Products Column is required"
    ),
    flat_products_image_column: Yup.string().required(
      "Flat Products Image Column is required"
    ),
    coated_steel_column: Yup.string().required(
      "Coated Steel Column is required"
    ),
    coated_steel_image_column: Yup.string().required(
      "Coated Steel Image Column is required"
    ),
    long_products_column: Yup.string().required(
      "Long Products Column is required"
    ),
    long_products_image_column: Yup.string().required(
      "Long Products Image Column is required"
    ),
    seamless_pipe_column: Yup.string().required(
      "Seamless Pipe Column is required"
    ),
    seamless_pipe_image_column: Yup.string().required(
      "Seamless Pipe Image Column is required"
    ),
    secondary_products_column: Yup.string().required(
      "Secondary Products Column is required"
    ),
    secondary_product_image_column: Yup.string().required(
      "Secondary Product Image Column is required"
    ),
    raw_material_column: Yup.string().required(
      "Raw Material Column is required"
    ),
    raw_material_image_column: Yup.string().required(
      "Raw Material Image Column is required"
    ),
    scrap_column: Yup.string().required("Scrap Column is required"),
    scrap_image_column: Yup.string().required("Scrap Image Column is required"),
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("importdata-get");
      const profileData = response.data;
      console.log('profileData:', profileData);
      setInitialData(profileData.data[0]);
      setImageFolderPath(profileData?.image_folder)
    } catch (error) {
      console.error("Error fetching company profile data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      const test = { created_by: 1 };

      const data = { ...values, ...test };
      // Append each key-value pair to the FormData object
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      let response;
      // if (initialData && initialData?.id) {
      //   response = await axiosInstance.put(
      //     `/import/update/${initialData?.id}`,
      //     data,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data", // Ensure correct content type
      //       },
      //     }
      //   );
      // } else {
        response = await axiosInstance.post("/importdata-save", data, {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct content type
          },
        });
      // }
      if (response) {
        notify("Import data saved successfully!")
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <ImportPageBanner imageFolderPath={imageFolderPath} />
      <ImportDetailsPage
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        initialData={initialData}
        validationSchema={validationSchema}
        imageFolderPath={imageFolderPath}
      />
    </>
  );
};

export default ImportPage;
